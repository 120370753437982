import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { useLoginMutation } from "../../../features/auth/auth-api-slice";
import { setCredentials } from "../../../features/auth/auth-slice";
import { CssBaseline, TextField, Button, Link, Box, Grid, Typography, Avatar, Container } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import Loading from "../../loading";

export default function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const usernameInputRef = useRef<HTMLInputElement>();

  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    usernameInputRef?.current?.focus();
  }, []);

  useEffect(() => {
    setErrorMessage("");
  }, [username, password]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    try {
      const userData = await login({ username, password }).unwrap();
      //const userData = { accessToken: "Access" };
      console.log("======>>>>>>>", userData);

      dispatch(setCredentials({ ...userData, username }));
      setUsername("");
      setPassword("");
      navigate("/");
    } catch (err: any) {
      console.error(err);
      if (!err.response) {
        setErrorMessage("No Server Response");
      } else if (err.response?.status === 400) {
        setErrorMessage("Missing username and/or password");
      } else if (err.response?.status === 401) {
        setErrorMessage("Given username and password login failed");
      } else {
        setErrorMessage("Login failed");
      }
    }
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={{ mt: 8, display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            inputRef={usernameInputRef}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            name="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}
          <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to="/forgot-password" variant="body2">
                Forgot Password?
              </Link>
            </Grid>
            {errorMessage && <p>{errorMessage}</p>}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
