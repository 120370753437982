import { Popover } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

const ArrowStyle = styled("span")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    top: -7,
    zIndex: 1,
    width: 12,
    right: 20,
    height: 12,
    content: "''",
    position: "absolute",
    borderRadius: "0 0 4px 0",
    transform: "rotate(-135deg)",
    background: theme.palette.background.paper,
    borderRight: `solid 1px ${theme.palette.grey[500]}`,
    borderBottom: `solid 1px ${theme.palette.grey[500]}`,
  },
}));

export default function MenuPopover({
  children,
  sx,
  open,
  onClose,
  anchorEl,
}: {
  children: any;
  sx: any;
  open: boolean;
  onClose: any;
  anchorEl: Element;
}) {
  return (
    <Popover
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      anchorEl={anchorEl}
      PaperProps={{
        sx: {
          mt: 1.5,
          ml: 0.5,
          overflow: "inherit",
          boxShadow: (theme) => theme.shadows[20],
          border: (theme) => `solid 1px ${theme.palette.grey[500]}`,
          width: 200,
          ...sx,
        },
      }}
      open={open}
      onClose={onClose}
    >
      <ArrowStyle />
      {children}
    </Popover>
  );
}
