import { useState } from "react";
import { CssBaseline, Toolbar, Box } from "@mui/material";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentToken } from "../../features/auth/auth-slice";
import LayoutAppBar from "./layout-app-bar";
import SideDrawer from "./side-drawer";

const Layout = () => {
  const [open, setOpen] = useState(true);
  const token = useAppSelector(selectCurrentToken);

  const location = useLocation();

  return token ? (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CssBaseline />
      <LayoutAppBar open={open} setOpen={setOpen} />
      <SideDrawer open={open} />
      <Box component="main" sx={{ flexGrow: 1, display: "flex", flexDirection: "column", height: "100vh" }}>
        <Toolbar />
        <Box sx={{ flexGrow: 1 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default Layout;
