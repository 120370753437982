import { Container, Grid, Paper, Stack } from "@mui/material";
import { useAppSelector } from "../../../app/hooks";
import { selectDashboardData } from "../../../features/dashboard/dashboard-slice";
import Collections from "./collections";
import CustomerPayments from "./customer-payments";
import PaymentsTable from "./payments-table";

const topPillStyle = {
  height: 200,
  p: 1,
};

export default function Dashboard() {
  const dashboardData = useAppSelector(selectDashboardData);

  return (
    <Container maxWidth="xl" sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {/* Top Pills */}
        <Grid item xs={12} md={4}>
          <Paper sx={topPillStyle}>
            <CustomerPayments data={dashboardData.customerPayments} />
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper sx={topPillStyle}>
            <Collections data={dashboardData.collections} />
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack>
            <Paper sx={{ ...topPillStyle, height: 96, mb: 1 }}>
              {/* Chart control */}
              Half info 3
            </Paper>
            <Paper sx={{ ...topPillStyle, height: 96 }}>
              {/* Chart control */}
              Half info 4
            </Paper>
          </Stack>
        </Grid>

        <Grid item xs={12} md={8}>
          <Paper>
            <PaymentsTable data={dashboardData.payments} />
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper></Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
