import { useContext, useEffect, useMemo } from "react";
import MapContext from "../map-context";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import ClusterSource from "ol/source/Cluster";
import { Fill, Stroke, Style, Text } from "ol/style";
import CircleStyle from "ol/style/Circle";

const ClusterLayer = ({
  source,
  zIndex = 0,
  distance,
  minDistance,
}: {
  source: VectorSource;
  zIndex?: number;
  distance?: number;
  minDistance: number;
}) => {
  const map = useContext(MapContext);

  const styleCache = useMemo(() => new Map<number, Style>(), []);

  useEffect(() => {
    if (!map) return;

    const clusterSource = new ClusterSource({
      distance,
      minDistance,
      source,
    });

    const layer = new VectorLayer({
      source: clusterSource,
      style: (feature) => {
        const size = feature.get("features").length;
        let style = styleCache.get(size);
        if (!style) {
          style = new Style({
            image: new CircleStyle({
              radius: 10,
              stroke: new Stroke({
                color: "#FFF",
              }),
              fill: new Fill({
                color: "#3399CC",
              }),
            }),
            text: new Text({
              text: size.toString(),
              fill: new Fill({
                color: "#FFF",
              }),
            }),
          });

          styleCache.set(size, style);
        }

        return style;
      },
    });

    map.addLayer(layer);
    layer.setZIndex(zIndex);

    return () => {
      map?.removeLayer(layer);
    };
  }, [map]);

  return null;
};

export default ClusterLayer;
