import { apiSlice } from "../../app/api/api-slice";

export const customersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query({
      query: () => "/customers",
      //keepUnusedDataFor: 10,
    }),
  }),
});

export const { useGetCustomersQuery } = customersApiSlice;
