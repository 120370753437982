import { Container } from "@mui/material";
import { fromLonLat, toLonLat } from "ol/proj";
import OSM from "ol/source/OSM";
import Map from "../../common/map";
import Layers from "../../common/map/layers";
import Controls from "../../common/map/controls";
import TileLayer from "../../common/map/layers/tile-layer";
import VectorLayer from "../../common/map/layers/vector-layer";
import ZoomControl from "../../common/map/controls/zoom";
import VectorSource from "ol/source/Vector";
import ClusterSource from "ol/source/Cluster";
import GeoJSON from "ol/format/GeoJSON";
import { Feature } from "ol";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { useEffect, useMemo, useRef, useState } from "react";
import { Point } from "ol/geom";
import ClusterLayer from "../../common/map/layers/cluster-layer";
import { useGetVehicleRoutesQuery } from "../../../features/vehicle-tracking/vehicleTrackingApiSlice";
import { useGetCustomersQuery } from "../../../features/customers/customersApiSlice";
import { useAppSelector } from "../../../app/hooks";
import { selectCurrentToken } from "../../../features/auth/auth-slice";
import { Box, height } from "@mui/system";
import TrackingVectorLayer from "../../common/map/layers/tracking-vector-layer";
import FeaturesPopup from "../../common/features-popup";

const center = fromLonLat([-0.062867, 5.62237]);

const trucks: any[] = [];

const truckPositions = [
  [-0.06438825827505314, 5.622565243344454],
  [-0.06423661449809143, 5.622518985950464],
  [-0.06408602738362264, 5.622484359407508],
  [-0.0640142228632345, 5.622459501053768],
  [-0.06393332638370929, 5.622445238347424],
  [-0.06390280973618004, 5.622433441738053],
  [-0.06388581082544538, 5.622428155068988],
  [-0.0638679296867518, 5.6224249831725075],
  [-0.06381848707360545, 5.622412216056105],
  [-0.0636966093251084, 5.6223900463768075],
  [-0.06361546894713625, 5.622369332128656],
  [-0.06346086166228118, 5.622322207103068],
  [-0.06337524031795184, 5.622310667980642],
  [-0.0633698403971056, 5.622305337739348],
  [-0.06330548786770845, 5.622289982339581],
  [-0.06325019340617997, 5.62227030421387],
  [-0.06321514693348057, 5.622253347968922],
  [-0.06314306416182285, 5.622214970095612],
  [-0.06303476929520434, 5.6222037971258345],
  [-0.06296299312743368, 5.62218800824337],
  [-0.06289728811224482, 5.622178150936747],
  [-0.06283751472838506, 5.622164501631218],
  [-0.06282095930533736, 5.622159215090946],
  [-0.06275642189049949, 5.622142408979357],
  [-0.06269578896077482, 5.6221304476598135],
  [-0.06261032401739605, 5.622100101299367],
  [-0.06254729641240894, 5.622090291499532],
  [-0.062470236635460176, 5.622065267243741],
  [-0.06243828996102714, 5.622061435610362],
  [-0.062407220767137984, 5.622047515819958],
  [-0.06238288182528333, 5.622046987192064],
  [-0.062333538775979885, 5.622017616190405],
  [-0.06228603956992328, 5.6220131204908625],
  [-0.06224270015441015, 5.622009026642985],
  [-0.062208988232823455, 5.62200074734281],
  [-0.06217349392063997, 5.621987884806828],
  [-0.062133878852214344, 5.621979128717555],
  [-0.06197688711222641, 5.621953268337634],
  [-0.06185736698278007, 5.6219088382575535],
  [-0.061712905517261385, 5.621859526238907],
  [-0.06157397690020965, 5.621843784461674],
  [-0.06143284996625584, 5.621803987874728],
  [-0.06140406929015425, 5.621823972982696],
  [-0.06138814197648896, 5.621921187390939],
  [-0.06137159116898364, 5.621992869631768],
  [-0.0613362738298825, 5.622104517336595],
  [-0.061295691080027505, 5.62221159832211],
  [-0.06129829899334818, 5.622261745904879],
  [-0.061285554557712216, 5.622312998903055],
  [-0.06126199762061036, 5.622388602493842],
];

export default function LiveCollections() {
  const token = useAppSelector(selectCurrentToken);

  const tileSource = new OSM();
  const vectorSource = new VectorSource();

  const { data } = useGetCustomersQuery("");
  if (data) {
    vectorSource.addFeatures(
      data.map((c: any) => {
        const f = new Feature();
        f.setGeometry(new Point(fromLonLat([c.longitude, c.latitude])));

        return f;
      })
    );

    return (
      <Box sx={{ height: "100%" }}>
        <Map zoom={16} center={center}>
          <Layers>
            <TileLayer source={tileSource} />
            <ClusterLayer source={vectorSource} zIndex={1} distance={20} minDistance={15} />
            <TrackingVectorLayer zIndex={1} showRoute={false} />
          </Layers>
          <Controls>
            <ZoomControl />
          </Controls>
        </Map>
        <FeaturesPopup />
      </Box>
    );
  } else {
    return <p>LOADING...</p>;
  }
}
