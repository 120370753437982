import React from "react";
import { styled, CSSObject, Theme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, Stack, Toolbar } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MapIcon from "@mui/icons-material/Map";
import LogOutIcon from "@mui/icons-material/Logout";
import { Link } from "react-router-dom";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: theme.spacing(7),
  [theme.breakpoints.up("sm")]: {
    width: theme.spacing(9),
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  whiteSpace: "nowrap",
  width: drawerWidth,
  boxSizing: "border-box",
  flexShrink: 0,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideDrawer({ open }: { open: boolean }) {
  const primaryMenuItems = [
    { caption: "Dashboard", icon: <DashboardIcon />, to: "/" },
    //{ caption: "Map View", icon: <MapIcon />, to: "/map" },
    { caption: "Collections", icon: <MapIcon />, to: "/collections" },
  ];

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar></Toolbar>
      <Stack sx={{ flexGrow: 1, justifyContent: "space-between" }}>
        <Box>
          <List>
            {primaryMenuItems.map((link) => (
              <li key={link.caption}>
                <ListItem button component={Link} to={link.to}>
                  <ListItemIcon>{link.icon}</ListItemIcon>
                  <ListItemText primary={link.caption} />
                </ListItem>
              </li>
            ))}
          </List>

          <Divider />
        </Box>
        <Box>
          <List>
            <li>
              <ListItem button component={Link} to="/login">
                <ListItemIcon>
                  <LogOutIcon />
                </ListItemIcon>
                <ListItemText primary="Log Out" />
              </ListItem>
            </li>
          </List>
        </Box>
      </Stack>
    </Drawer>
  );
}
