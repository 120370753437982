import { Stack, Box, Typography } from "@mui/material";
import { CollectionsStats, Rag } from "../../../features/dashboard/dashboard-slice";

const boxStyle = {
  borderLeftWidth: 4,
  borderLeftStyle: "solid",
  pl: 1,
};

const numberStyle = {
  textAlign: "right",
};

export default function Collections({ data }: { data: CollectionsStats }) {
  return (
    <Box sx={{ m: 1 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Collection Rates
      </Typography>
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr auto auto", rowGap: 2, columnGap: 2 }}>
        <Box>Today</Box>
        <Box sx={numberStyle}>{data.today.toLocaleString()}</Box>
        <Box sx={numberStyle}>{((data.today * 100) / data.total).toFixed(2)}%</Box>
        <Box>Yesterday</Box>
        <Box sx={numberStyle}>{data.yesterday.toLocaleString()}</Box>
        <Box sx={numberStyle}>{((data.yesterday * 100) / data.total).toFixed(2)}%</Box>
        <Box>Last Week Average</Box>
        <Box sx={numberStyle}>{data.lastWeekAvg.toLocaleString()}</Box>
        <Box sx={numberStyle}>{((data.lastWeekAvg * 100) / data.total).toFixed(2)}%</Box>
      </Box>
    </Box>
  );
}
