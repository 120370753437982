import { useContext, useEffect, useRef, useState } from "react";
import MapContext from "../map-context";
import OlVectorLayer from "ol/layer/Vector";
import { Feature } from "ol";
import VectorSource from "ol/source/Vector";
import { VehiclePositionInfo } from "../../../../models/vehicle-position-info";
import { TimePosition } from "../../../../models/vehicle-route";
import Point from "ol/geom/Point";
import { fromLonLat } from "ol/proj";
import { Fill, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import { HubConnectionBuilder } from "@microsoft/signalr";

const style = new Style({
  image: new CircleStyle({
    radius: 5,
    fill: new Fill({ color: "#3399CC" }),
    stroke: new Stroke({ color: "#f00", width: 2 }),
  }),
});

const TrackingVectorLayer = ({ zIndex = 0, showRoute = false }: { zIndex: number; showRoute: boolean }) => {
  const map = useContext(MapContext);

  console.log("Tracking VL");

  useEffect(() => {
    if (!map) return;

    const source = new VectorSource({ features: [] });

    const layer = new OlVectorLayer({ source, zIndex });

    map.addLayer(layer);
    layer.setZIndex(zIndex);

    const connection = new HubConnectionBuilder().withUrl("https://electionsghana.com/vehiclehub").build();

    connection.on("UpdateVehiclePosition", (data) => {
      const { id, longitude, latitude } = data;

      let feature = source.getFeatureById(id);

      if (!feature) {
        feature = new Feature();
        feature.setStyle(style);
        feature.setId(id);

        source.addFeature(feature);
      }

      feature.setGeometry(new Point(fromLonLat([longitude, latitude])));
    });

    connection.start();

    return () => {
      connection.stop();
      map?.removeLayer(layer);
    };
  }, [map]);

  return null;
};

export default TrackingVectorLayer;
