import { Stack, Box, Typography } from "@mui/material";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { CollectionsStats, Rag } from "../../../features/dashboard/dashboard-slice";

const boxStyle = {
  borderLeftWidth: 4,
  borderLeftStyle: "solid",
  pl: 1,
  mb: 1,
  lineHeight: 1,
};

const numberStyle = {
  textAlign: "right",
};

export default function CustomerPayments({ data }: { data: Rag }) {
  const renderData = [
    {
      title: "In arreas for more than a month",
      value: data.red,
      color: "red",
    },
    {
      title: "In arreas for less than a month",
      value: data.amber,
      color: "orange",
    },
    {
      title: "In good standing",
      value: data.green,
      color: "green",
    },
  ];

  const series = renderData.map((d) => d.value!);
  const options: ApexOptions = {
    chart: { type: "donut" },
    legend: { show: false },
  };

  return (
    <Box sx={{ m: 1 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Customer Payments
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ flex: 1 }}>
          {renderData.map(({ color, title, value }) => (
            <Box key={color} sx={{ borderLeftColor: color, ...boxStyle }}>
              <Stack>
                <Typography variant="subtitle2">{title}</Typography>
                <Box>{value?.toLocaleString()}</Box>
              </Stack>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
