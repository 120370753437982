import { forwardRef, ReactNode, useEffect, useRef, useState } from "react";
import * as ol from "ol";
import { Coordinate } from "ol/coordinate";
import MapContext from "./map-context";
import { Box } from "@mui/material";
import { toLonLat } from "ol/proj";
import "./map.css";

export interface MapProperties {
  children: ReactNode;
  zoom: number;
  center: Coordinate;
}

const Map = forwardRef<HTMLElement, MapProperties>(({ children, zoom, center }, ref) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<ol.Map | null>(null);

  useEffect(() => {
    const options = {
      view: new ol.View({ zoom, center }),
      layers: [],
      controls: [],
      overlays: [],
    };

    const mapObject = new ol.Map(options);
    mapObject.setTarget(mapRef.current || undefined);

    setMap(mapObject);

    console.log("mounting map");

    return () => mapObject.setTarget(undefined);
  }, []);

  useEffect(() => {
    if (map) {
      map?.on("click", function (e) {
        console.log(e, toLonLat(e.coordinate));
      });
    } else console.log("========");
  }, [map]);

  useEffect(() => {
    map?.getView().setZoom(zoom);
  }, [zoom]);

  useEffect(() => {
    map?.getView().setCenter(center);
  }, [center]);

  return (
    <MapContext.Provider value={map}>
      <Box ref={mapRef} sx={{ height: "100%" }}>
        {children}
      </Box>
    </MapContext.Provider>
  );
});

export default Map;
