import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { identityBaseQuery } from "../../app/api/api-slice";

export interface UserLoginData {
  username: string;
  password: string;
}

export const unauthApiSlice = createApi({
  reducerPath: "identity",
  baseQuery: identityBaseQuery,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials: UserLoginData) => ({
        url: "/auth/login/password",
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const { useLoginMutation } = unauthApiSlice;
