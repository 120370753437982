import { Stack, Box, Typography, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { CollectionsStats, Rag, PaymentRecord } from "../../../features/dashboard/dashboard-slice";

const boxStyle = {
  borderLeftWidth: 4,
  borderLeftStyle: "solid",
  pl: 1,
};

const numberStyle = {
  textAlign: "right",
};

export default function PaymentsTable({ data }: { data: PaymentRecord[] }) {
  return (
    <Box sx={{ m: 2 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Payments
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date/Time</TableCell>
            <TableCell>Customer ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Address</TableCell>
            <TableCell align="right">Amount (GHS)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((p) => (
            <TableRow>
              <TableCell>{new Date(p.time).toDateString()}</TableCell>
              <TableCell>{p.customerId}</TableCell>
              <TableCell>{p.customerName}</TableCell>
              <TableCell>{p.customerAddress}</TableCell>
              <TableCell align="right">{p.amount.toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
