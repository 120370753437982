import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/layout";
import ForgotPasswordPage from "./components/pages/authentication/forgot-password";
import LoginPage from "./components/pages/authentication/login-page";
import Dashboard from "./components/pages/dashboard";
import Collections from "./components/pages/collections";
import NotFoundPage from "./components/pages/not-found-page";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/collections" element={<Collections />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
