import { useContext, useEffect } from "react";
import { Zoom } from "ol/control";
import MapContext from "../map-context";

const ZoomControl = () => {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const control = new Zoom();
    map.addControl(control);

    return () => {
      map?.removeControl(control);
    };
  }, [map]);

  return null;
};

export default ZoomControl;
