import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface Rag {
  red?: number;
  amber?: number;
  green?: number;
}

export interface CollectionsStats {
  today: number;
  yesterday: number;
  lastWeekAvg: number;
  total: number;
}

export interface AreaStat {
  areaId?: string;
  name?: string;
  customers?: number;
  collectionRate?: number;
}

export interface DashboardData {
  customerPayments: Rag;
  collections: CollectionsStats;
  areaStats: AreaStat[];
  payments: PaymentRecord[];
}

export interface PaymentRecord {
  time: number;
  customerId: string;
  customerName: string;
  customerAddress: string;
  amount: number;
  channel: string;
}

export interface DashboardState {
  data: DashboardData;
}

const initialState: DashboardState = {
  data: {
    customerPayments: { red: 1525, amber: 2558, green: 452 },
    collections: { today: 2334, yesterday: 4510, lastWeekAvg: 3212, total: 5123 },
    areaStats: [
      { name: "Agboba", customers: 234, collectionRate: 74.3 },
      { name: "Haatso No. 1", customers: 1234, collectionRate: 53.3 },
      { name: "Madina Old Road", customers: 783, collectionRate: 40.3 },
      { name: "Madina East", customers: 1098, collectionRate: 30.3 },
    ],
    payments: [
      {
        time: new Date("2022-07-08T12:34:21").getTime(),
        customerId: "025-0253-032",
        customerName: "Andreas Botas",
        customerAddress: "555 Franofa Drive, Madina",
        amount: 50,
        channel: "MTN",
      },
      {
        time: new Date("2022-07-07T17:34:21").getTime(),
        customerId: "028-0209-032",
        customerName: "Adjoa Mansah",
        customerAddress: "17 Adoato Panyin Street, Haatso",
        amount: 250,
        channel: "MTN",
      },
      {
        time: new Date("2022-07-05T20:34:21").getTime(),
        customerId: "024-4127-010",
        customerName: "Comfort Agyeman",
        customerAddress: "Plt 13 Block 2 Atomic Junction, Haatso",
        amount: 200,
        channel: "MTN",
      },
      {
        time: new Date("2022-07-03T12:34:21").getTime(),
        customerId: "025-0253-032",
        customerName: "Andreas Botas",
        customerAddress: "555 Franofa Drive, Madina",
        amount: 50,
        channel: "MTN",
      },
      {
        time: new Date("2022-07-02T17:34:21").getTime(),
        customerId: "028-0209-032",
        customerName: "Adjoa Mansah",
        customerAddress: "17 Adoato Panyin Street, Haatso",
        amount: 250,
        channel: "MTN",
      },
      {
        time: new Date("2022-07-01T20:34:21").getTime(),
        customerId: "024-4127-010",
        customerName: "Comfort Agyeman",
        customerAddress: "Plt 13 Block 2 Atomic Junction, Haatso",
        amount: 200,
        channel: "MTN",
      },
      {
        time: new Date("2022-05-30T12:34:21").getTime(),
        customerId: "025-0253-032",
        customerName: "Andreas Botas",
        customerAddress: "555 Franofa Drive, Madina",
        amount: 50,
        channel: "MTN",
      },
      {
        time: new Date("2022-05-29T17:34:21").getTime(),
        customerId: "028-0209-032",
        customerName: "Adjoa Mansah",
        customerAddress: "17 Adoato Panyin Street, Haatso",
        amount: 250,
        channel: "MTN",
      },
      {
        time: new Date("2022-05-28T20:34:21").getTime(),
        customerId: "024-4127-010",
        customerName: "Comfort Agyeman",
        customerAddress: "Plt 13 Block 2 Atomic Junction, Haatso",
        amount: 200,
        channel: "MTN",
      },
    ],
  },
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setDashboardData } = dashboardSlice.actions;

export const selectDashboardData = (state: RootState) => state.dashboard.data;
export default dashboardSlice.reducer;
