import { Typography, Link } from "@mui/material";

export default function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {`Copyright © ${new Date().getFullYear()} - `}
      <Link color="inherit" href="https://www.renaeon.com/">
        Waste Collection Manager
      </Link>
    </Typography>
  );
}
