import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";

import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link as RouterLink } from "react-router-dom";
import Copyright from "../../common/copyright";

export default function NotFoundPage() {
  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <ErrorIcon />
        </Avatar>
        <Typography component="h2" variant="h4">
          404 :: Oops - The page you're looking for cannot be found.
        </Typography>
        <Typography component="h1" variant="h5">
          <Link component={RouterLink} to="/" underline="none">
            Home
          </Link>
        </Typography>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
