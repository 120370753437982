import { AppBar, Box, IconButton, Stack, Toolbar, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsMenu from "./notifications-menu";
import AccountMenu from "./account-menu";

export default function LayoutAppBar({ open, setOpen }: { open: boolean; setOpen: (v: boolean) => void }) {
  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        {open ? (
          <IconButton edge="start" color="inherit" aria-label="close drawer" onClick={() => setOpen(false)}>
            <ChevronLeftIcon />
          </IconButton>
        ) : (
          <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={() => setOpen(true)}>
            <MenuIcon />
          </IconButton>
        )}
        <Typography variant="h6" noWrap sx={{ ml: 2 }}>
          Waste Collection Manager
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <NotificationsMenu totalUnRead={3} />
          <AccountMenu />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
