import { useContext, useEffect } from "react";
import MapContext from "../map-context";
import OlTileLayer from "ol/layer/Tile";

const TileLayer = ({ source, zIndex = 0 }: { source: any; zIndex?: number }) => {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const tileLayer = new OlTileLayer({ source, zIndex });

    map.addLayer(tileLayer);
    tileLayer.setZIndex(zIndex);

    return () => {
      map?.removeLayer(tileLayer);
    };
  }, [map]);

  return null;
};

export default TileLayer;
