import { Typography, Box } from "@mui/material";

export default function FeaturesPopup() {
  return (
    <Box sx={{ position: "absolute", width: "400px", height: "400px" }}>
      <Typography variant="body2" color="text.secondary" align="center">
        {`Copyright © ${new Date().getFullYear()} - `}
      </Typography>
    </Box>
  );
}
